import React from 'react';

const RecordNotFound = () => {
  return (
    <div className='w-100 d-flex justify-content-center align-items-center userlist-box userListing-box min-vh-75'>
      <h1>Record Not Found</h1>
    </div>
  )
}

export default RecordNotFound;