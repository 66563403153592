import React from 'react';

const Index = () => {
  return (
    <div className='m-5 text-center'>
     <h1> Data Not Found</h1>
    </div>
  )
}

export default Index;