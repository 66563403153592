import React from 'react';
import { FaEye } from "react-icons/fa";
import MetaTags from '../../HeadTag/MetaTags';
import { Row, Col, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import { FiEdit2, FiDelete } from "react-icons/fi";
import Tablelist from '../../../Hook/list';
import axios from '../../../Hook/utils/constants/server';
import { local } from '../../../Hook/utils/constants/server';
import { IoMdArrowDown } from "react-icons/io";
import { AiOutlineUserDelete } from "react-icons/ai";
import { RiArrowDropDownLine, RiInstallFill } from "react-icons/ri";
import {
    Tooltip,
    Bar, BarChart,
    PieChart,
    Pie,
    Cell,
    Legend,
    ResponsiveContainer,
    XAxis, YAxis, CartesianGrid,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Radar
} from "recharts";
import RevenueData from "../../Data/Chart/RevenueDeviceData.json";
import PieCharts from '../../../Hook/Recharts/PieCharts';
import data from "../../Data/Chart/Bar.json";
import { ReactCountryFlag } from "react-country-flag";
import "../Chartpage.css";
import { Backdrop, Typography } from '@mui/material';
import countryList from "../../../Components/Data/countries/countries.json";
import { HiArrowNarrowUp, HiArrowNarrowDown } from "react-icons/hi";
import Loading from '../../../Hook/Spinner';
import { createSearchParams, useNavigate } from "react-router-dom";
import "./index.scss";
const Widgets = () => {
    let [users, setusers] = useState({
        activeInstalls: [],
        allInstalls: [],
        loading: false,
        toast: false,
        tabledata: {
            thead: [
                {
                    key: "date",
                    title: "Date",
                    type: "text"
                },
                {
                    link: "shop_name",
                    title: "Shop Name",
                    type: "text-link"
                },
                {
                    key: "store_owner",
                    title: "Shop Owner",
                    type: "text-link"
                },
                {
                    key: "active_plan_name",
                    title: "Plan",
                    type: "text"
                }, {
                    key: "country",
                    title: "Country",
                    type: "text"
                },
                {
                    key: "status",
                    title: "Status",
                    type: "text"
                },
                {
                    element: [{
                        type: "edit", action: (e, store_id) => {
                            navigate({
                                pathname: "/dashboard/reports/storedetails",
                                search: createSearchParams({ id: store_id }).toString()
                            })
                        }, icon: FaEye
                    }],
                    title: "Action",
                    type: "action"
                },
            ],
            tbody: []
        }
    });
    const navigate = useNavigate();
    const [countryWiseInstalls, setCountryWiseInstalls] = React.useState({
        frequency: "all",
        tabledata: {
            thead: [{
                type: "text",
                title: "Install Count",
                key: "install_count"
            }, {
                type: "text",
                title: "Country",
                key: "country_name"
            },
            {
                type: "thumbnail",
                title: "Flag",
                image: "image"
            }],
            loading: false,
            tbody: []
        }
    });
    const radarData = [{
        name: "Hardeep Singh",
        x: 10
    }, {
        name: "Sahil Garg",
        x: 15
    }, {
        name: "Shashvat Gupta",
        x: 14
    }, {
        name: "Chetan Singla",
        x: 25
    }]
    const [barData, setBarData] = useState([]);
    let [monthlyRevenue, setMonthlyRevenue] = useState({
        currentMonthRevenue: {
            totalprice: 0,
            currentMonthName: ""
        },
        previousMonthRevenue: {
            totalprice: 0,
            previousMonthName: ""
        }
    });
    let [widgetretention, setWidgetRetention] = useState({
        currentMonthRetentionPercent: null,
        previousMonthRetentionPercent: null,
        currentMonthActiveRecords: null,
        currentMonthTotalRecords: null,
        previousMonthActiveRecords: null,
        previousMonthTotalRecords: null
    });
    let [revenueByDeviceData, setrevenueByDeviceData] = useState("week");
    const formatdata = data => {
        let modifiedData = [];
        const dateformater = date => {
            let datemodified = new Date(date);
            let day = datemodified.getDate();
            let month = datemodified.getMonth() + 1;
            let year = datemodified.getFullYear().toString().slice(-2);
            const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
            return formattedDate;
        }
        modifiedData = data.map((key, index) => {
            return {
                active_plan_name: key.active_plan_name,
                app_install_id: key.app_install_id,
                country: key.country,
                date: dateformater(key.date),
                shop_email: key.shop_email,
                shop_phone: key.shop_phone,
                status: key.status,
                store_owner: key.store_owner,
                shop_name: key.shop_name
            }
        })
        return modifiedData;
    }
    const [appwidget, setAppWidget] = useState([]);
    const [showdropdownfilter, setShowdropdownfilter] = useState({
        showdropdown: false,
        status: "all",
        frequency: "all"
    });
    const [charttabledata, setChartTableData] = useState({
        frequencyType: "week",
        thead: [{
            key: "name",
            title: "Plan Name",
            type: "text"
        }, {
            key: "percent",
            title: "Percent(%)",
            type: "text"
        }, {
            key: "value",
            title: "Count",
            type: "text"
        }],
        tbody: [],
        loading: false
    });
    const setAll = () => setShowdropdownfilter({ ...showdropdownfilter, status: "all" });
    const setActive = () => setShowdropdownfilter({ ...showdropdownfilter, status: "Active" });
    const setInActive = () => setShowdropdownfilter({ ...showdropdownfilter, status: "inActive" });
    const fetchMonthlyRevenueLastMonth = frequency => {
        let date = new Date();
        let currentDate = date.getDate();
        let currentMonth = date.getMonth() + 1;
        let year = date.getFullYear();
        let previousMonthDate = `${year}-${currentMonth - 1}-${currentDate}`;
        let currentMonthDate = `${year}-${currentMonth}-${currentDate}`;
        axios.get("/user/getpaymentlogs", {
            params: {
                startdate: previousMonthDate,
                enddate: currentMonthDate
            }
        }).then(resonse => {
        }).catch(er => console.log("Errr", er));
    }
    const allActiveBarData = frequency => {
        let testValue = [];
        const formatDate = date => {
            let day = new Date(date).getDate();
            let month = new Date(date).getMonth() + 1;
            let year = new Date(date).getFullYear()
            return `${day}-${month}-${year}`
        }
        const data = frequency.map(item => ({
            name: new Date(item.currentDate),
            label: Number(new Date(item.currentDate).toLocaleString().split(",")[0].split("/")[0]),
            installcount: item.count
        }));
        let startDate = new Date(data[0].name);
        for (let i = 0; i <= 7; i++) {
            let nextDate = new Date(data[0].name);
            nextDate.setDate(startDate.getDate() + i);
            testValue.push(nextDate)
        }
        let dates = data.map((dataItem) => formatDate(dataItem.name));

        testValue.forEach(tstvalue => {
            if (!dates.includes(formatDate(tstvalue))) {
                data.push({
                    name: (tstvalue),
                    label: null,
                    installcount: 0
                })
            }
        });

        data.sort((a, b) => {
            let firstDate = Date.parse(a.name);
            let secondDate = Date.parse(b.name);
            return new Date(firstDate) - new Date(secondDate);
        });
        // console.log("dates", data)
        setBarData(data);
    }
    const fetchTimely = frequency => {
        setShowdropdownfilter({ ...showdropdownfilter, frequency })
    }
    const calculateWidgetRevenue = (positive, previousPositive, currentMonth, previousMonth) => {
        let basicRevenuePrice = 4.99;
        let freeRevenuePrice = 0;
        let premiumRevenuePrice = 14.99;
        let proRevenuePrice = 9.99;
        let basicCurrentMonth = 0;
        let freeCurrentMonth = 0;
        let premiumCurrentMonth = 0;
        let proCurrentMonth = 0;
        let basicPreviousMonth = 0;
        let freePreviousMonth = 0;
        let premiumPreviousMonth = 0;
        let currentMonthName = "";
        let previousMonthName = "";
        let proPreviousMonth = 0;

        positive.forEach(positive => {
            if (positive.active_plan_name === "Basic") {
                basicCurrentMonth = basicCurrentMonth + (basicRevenuePrice * positive["count(app_install_id)"]);
            }
            else if (positive.active_plan_name === "No Plan") {
                freeCurrentMonth = freeCurrentMonth + (freeRevenuePrice * positive["count(app_install_id)"]);
            }
            else if (positive.active_plan_name === "Premium") {
                premiumCurrentMonth = premiumCurrentMonth + (premiumRevenuePrice * positive["count(app_install_id)"]);
            }
            else if (positive.active_plan_name === "Pro") {
                proCurrentMonth = proCurrentMonth + (proRevenuePrice * positive["count(app_install_id)"]);
            }
        });
        previousPositive.forEach(previousPositive => {
            if (previousPositive.active_plan_name === "Basic") {
                basicPreviousMonth = basicPreviousMonth + (basicRevenuePrice * previousPositive["count"]);
            }
            else if (previousPositive.active_plan_name === "No Plan") {
                freePreviousMonth = freePreviousMonth + (freeRevenuePrice * previousPositive["count"]);
            }
            else if (previousPositive.active_plan_name === "Premium") {
                premiumPreviousMonth = premiumPreviousMonth + (premiumRevenuePrice * previousPositive["count"]);
            }
            else if (previousPositive.active_plan_name === "Pro") {
                proPreviousMonth = proPreviousMonth + (proRevenuePrice * previousPositive["count"]);
            }
        });
        return {
            currentMonthRevenue: { totalprice: (basicCurrentMonth + freeCurrentMonth + premiumCurrentMonth + proCurrentMonth).toFixed(2), currentMonthName: currentMonth },
            previousMonthRevenue: { totalprice: (basicPreviousMonth + freePreviousMonth + premiumPreviousMonth + proPreviousMonth).toFixed(2), previousMonthName: previousMonth }
        }
    }
    const calculateWidgetRetention = retentionStats => {
        let currentMonthTotalRecords = 0;
        let currentMonthActiveRecords = 0;
        let previousMonthTotalRecords = 0;
        let previousMonthActiveRecords = 0;
        retentionStats.currentMonthRetention.forEach(retentionRecord => {
            currentMonthTotalRecords = currentMonthTotalRecords + retentionRecord["count(app_install_id)"];
            if (retentionRecord.status === "Active") {
                currentMonthActiveRecords = currentMonthActiveRecords + retentionRecord["count(app_install_id)"];
            }
        });
        retentionStats.previousMonthRetention.forEach(retentionRecord => {
            previousMonthTotalRecords = previousMonthTotalRecords + retentionRecord["count(app_install_id)"];
            if (retentionRecord.status === "Active") {
                previousMonthActiveRecords = previousMonthActiveRecords + retentionRecord["count(app_install_id)"];
            }
        });
        let currentMonthRetentionPercent = ((currentMonthActiveRecords / currentMonthTotalRecords) * 100).toFixed(2);
        let previousMonthRetentionPercent = ((previousMonthActiveRecords / previousMonthTotalRecords) * 100).toFixed(2);
        return {
            currentMonthRetentionPercent,
            currentMonthActiveRecords,
            currentMonthTotalRecords,
            previousMonthRetentionPercent,
            previousMonthActiveRecords,
            previousMonthTotalRecords
        };
    }
    const calculateTenure = tenure => {
        let date = new Date();
        let currentDate = date.getDate();
        let currentMonth = date.getMonth();
        let currentYear = date.getFullYear();
        let beginningDate = `${currentYear}-${currentMonth}-01`;
        let todayDate = `${currentYear}-${currentMonth}-${currentDate}`
        if (tenure === "week") {
            return {
                startdate: "",
                enddate: ""
            }
        }
        else if (tenure === "month") {
            return {
                startdate: beginningDate,
                enddate: todayDate
            }
        }
        else return {
            startdate: "",
            enddate: ""
        };
    }
    const calculateGraphData = data => {
        let totalactiveusers = 0;
        data.forEach(datakey => {
            totalactiveusers = totalactiveusers + datakey.count;
        });
        return data.map(datakey => {
            if (datakey.active_plan_name !== 'null' || datakey.active_plan_name !== 'No plan')
                return {
                    name: datakey.active_plan_name,
                    value: datakey.count,
                    percent: parseFloat(((datakey.count / totalactiveusers) * 100)).toFixed(2)
                }

            else {
                return;
            }
        });
    }
    const formatCountryData = data => {

        return data.map(dataitem => {

            return {
                install_count: dataitem.install_count,
                image: countryList.find(country => country.name === dataitem.country).code.toLocaleLowerCase(),
                country_name: dataitem.country,
                store_type: dataitem.store_type
            }
        });
    }
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length)
            return (
                <>
                    <div className='custom-tooltip bg-light rounded-2'>
                        <p className='m-0 p-0' style={{ color: "orangered" }}>Date: {payload[0].payload.name.toLocaleString().split(",")[0]}</p>
                        <p className='p-0 m-0' style={{ color: "orange", }}>Count: {payload[0].payload.installcount}</p>
                    </div>
                </>
            )
        else return null;
    }
    //for the top header cards//
    useEffect(() => {
        setusers((users) => ({ ...users, loading: true }))
        axios.get(`/user/appinstalls`, {
            params: {
                status: showdropdownfilter.status,
                frequency: showdropdownfilter.frequency
            }
        }).then(response => {
            allActiveBarData(response.data.data.barlist);
            setMonthlyRevenue(calculateWidgetRevenue(response.data.data.positive, response.data.data.previousPositive, response.data.data.currentMonth, response.data.data.previousMonth));
            setWidgetRetention(calculateWidgetRetention(response.data.data.retentionStats));
            setAppWidget([{ label: "Active Installs", type: "Active", count: response.data.data.totalInstalls }, { label: "Revenue", type: "revenue", count: response.data.data.list.length }, { label: "Retention", count: response.data.data.list.length, type: "retention" }]);
        }).finally(response => ({ ...users, loading: false }));
        return () => console.log("Component unmunts from the domn tree")
    }, []);
    //end//
    //for the chart data //
    useEffect(() => {
        setChartTableData({ ...charttabledata, loading: true });
        axios.get(`/user/appinstalls`, {
            params: {
                status: showdropdownfilter.status,
                frequency: showdropdownfilter.frequency
            }
        }).then(response => {
            let data;
            if (showdropdownfilter.frequency === "all")
                setChartTableData({
                    ...charttabledata, tbody: calculateGraphData(response.data.data.piechartdata)
                });
            else if (showdropdownfilter.frequency === "week") {
                data = response.data.data.piechartDataWeek;
                setChartTableData({ ...charttabledata, tbody: calculateGraphData(data) });
            }
            else if (showdropdownfilter.frequency === "month") {
                data = response.data.data.piechartDataMonth;
                setChartTableData({ ...charttabledata, tbody: calculateGraphData(data) });
            }
            else if (showdropdownfilter.frequency === "thisweek") {
                data = response.data.data.piechartdatathisweek;
                setChartTableData({ ...charttabledata, tbody: calculateGraphData(data) })
            }
            else if (showdropdownfilter.frequency === "thismonth") {
                data = response.data.data.piechartdatathismonth;
                setChartTableData({ ...charttabledata, tbody: calculateGraphData(data) })
            }
        }).finally(response => setChartTableData((charttabledata) => ({ ...charttabledata, loading: false })));
    }, [showdropdownfilter.frequency]);
    //end//

    //for the countrywiseinstalls//
    useEffect(() => {
        setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, loading: true } });
        axios("/user/appinstalls", {
            method: "GET",
            params: {
                status: showdropdownfilter.status,
                frequency: showdropdownfilter.frequency
            }
        }).then(response => {
            if (countryWiseInstalls.frequency === "all")
                setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, tbody: formatCountryData(response.data.data.countryWiseInstalls) } });
            else if (countryWiseInstalls.frequency === "week")
                setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, tbody: formatCountryData(response.data.data.countrywiseInstallsLastWeek) } });
            else if (countryWiseInstalls.frequency === "month")
                setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, tbody: formatCountryData(response.data.data.countrywiseInstallsLastMonth) } });
            else if (countryWiseInstalls.frequency === "thisweek")
                setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, tbody: formatCountryData(response.data.data.countrywiseInstallsThisWeek) } });
            else if (countryWiseInstalls.frequency === "thismonth")
                setCountryWiseInstalls({ ...countryWiseInstalls, tabledata: { ...countryWiseInstalls.tabledata, tbody: formatCountryData(response.data.data.countrywiseInstallsThisMonth) } });
        })
    }, [countryWiseInstalls.frequency]);
    //end//

    //for the table data//
    useEffect(() => {
        let dates = calculateTenure(showdropdownfilter.frequency);
        let payload = {
            startdate: dates.startdate,
            enddate: dates.enddate,
            status: showdropdownfilter.status,
            frequency: showdropdownfilter.frequency
        }
        axios.get(`/user/appinstalls`,
            {
                params: {
                    startdate: payload.startdate,
                    enddate: payload.enddate,
                    status: payload.status,
                    frequency: payload.frequency
                }
            }).then(response => {
                setusers({ ...users, allInstalls: response.data.data.totalInstalls, tabledata: { ...users.tabledata, tbody: formatdata(response.data.data.list) }, loading: false });
            }).finally(response => setusers((users) => ({ ...users, loading: false })));
    }, [showdropdownfilter.status]);
    // //for the table data end//
    return (
        <>
            <div className='h-100'>
                <MetaTags title={"Widgets"} />
                <div className='p-4 pb-0 heading-content bg-light'>
                    <h4 className="m-0">Widgets</h4>
                </div>
                <div className='container widget-container bg-light p-4'>
                    <Row className='bg-light d-flex justify-content-between mb-3'>
                        {appwidget.map((key, index) =>
                            <>
                                {key.type === "Active" && <Col index={key} xs={12} md={4} className="">
                                    <div className="grid-box h-75">
                                        <Row className="">
                                            <Col className=''>
                                                <h2 className='mt-0'>{key.count}</h2>
                                                <p>{key.label}</p>
                                                <p className='status-percentage mt-0'>
                                                    <span> Since last week</span>
                                                </p>
                                            </Col>
                                            <Col className=''>
                                                {barData.length > 0 ?
                                                    <BarChart className='m-0' data={barData} width={130} height={126}>
                                                        <Bar dataKey={"installcount"} fill={"#f5aa33"} />
                                                        <Tooltip content={<CustomTooltip />} />
                                                        <YAxis tick={{ fontSize: 10, fill: "blue" }} label={{ value: 'Install Count', angle: -90, fontSize: 11, style: { fill: "green", fontWeight: "bold" }, position: "center" }} key={"installcount"} tickLine={true} axisLine={true} />
                                                        <XAxis tick={{ fontSize: 0, fill: "blue" }} tickLine={false} axisLine={true} label={{ value: "Date", position: "insidebottom", style: { fill: "green", fontWeight: "bold", fontSize: 11 } }} />
                                                    </BarChart>
                                                    : <span>No Active Installs</span>}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>}
                                {key.type === "revenue" && <Col index={key} xs={12} md={4} className="">
                                    <div className="grid-box h-75">
                                        <Row className="content-overview align-items-end align-left">
                                            <Col  className=''>
                                                <h2 className='mt-0'>${monthlyRevenue.currentMonthRevenue.totalprice}</h2>
                                                <p className='d-flex gap-1 mt-0'>
                                                    <span>{key.label}</span>
                                                    -
                                                    <span style={{ color: "#f5aa33" }}>{monthlyRevenue.currentMonthRevenue.currentMonthName}</span>
                                                </p>
                                                <p className='status-percentage mt-0'>
                                                    <span className={monthlyRevenue.currentMonthRevenue.totalprice > monthlyRevenue.previousMonthRevenue.totalprice ? 'text-success' : 'text-danger'}>
                                                        {
                                                            monthlyRevenue.currentMonthRevenue.totalprice > monthlyRevenue.previousMonthRevenue.totalprice ? <ArrowUp className='text-success' /> : <ArrowDown className='text-danger' />
                                                        }
                                                        {
                                                            (monthlyRevenue.currentMonthRevenue.totalprice > monthlyRevenue.previousMonthRevenue.totalprice) ? (((monthlyRevenue.currentMonthRevenue.totalprice - monthlyRevenue.previousMonthRevenue.totalprice) / monthlyRevenue.currentMonthRevenue.totalprice) * 100).toFixed(2) : (((monthlyRevenue.previousMonthRevenue.totalprice - monthlyRevenue.currentMonthRevenue.totalprice) / monthlyRevenue.previousMonthRevenue.totalprice) * 100).toFixed(2)
                                                        }
                                                        %
                                                    </span>
                                                    <span>
                                                        Since last Month
                                                    </span>
                                                </p>
                                            </Col>
                                            <Col  className=''>
                                                <p className="d-flex gap-1 status-percentage mt-22">
                                                    <span>{monthlyRevenue.previousMonthRevenue.previousMonthName}</span>
                                                    -
                                                    <span style={{ color: "#f5aa33" }}>${monthlyRevenue.previousMonthRevenue.totalprice}</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>}
                                {key.type === "retention" && <Col index={key} xs={12} md={4} className="">
                                    <div className="grid-box h-75 row content-overview">
                                        {/* <Row className="content-overview align-items-end align-left"> */}
                                            <Col md={12} className=''>
                                                <div className="d-flex aling-items-center gap-2">
                                                    <h2 className='mt-0'>{widgetretention.currentMonthRetentionPercent}%
                                                    </h2>
                                                    <p className='status-percentage mt-0'>
                                                        {widgetretention.currentMonthActiveRecords} out of {widgetretention.currentMonthTotalRecords}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col md={6} xxl={12} className=''>

                                                <p className='mt-0 d-flex gap-1'>
                                                    <span>{key.label} </span> -
                                                    <span style={{ color: "#f5aa33" }}>
                                                        {monthlyRevenue.currentMonthRevenue.currentMonthName}
                                                    </span>
                                                </p>
                                                <p className='status-percentage'>
                                                    Since last month
                                                </p>
                                            </Col>
                                            <Col md={6} xxl={12} className='d-flex flex-md-column align-items-center align-items-lg-end gap-2 gap-ms-0'>
                                                <p className='status-percentage mt-sm-22 mt-md-0 d-flex gap-1'>
                                                    <span>{monthlyRevenue.previousMonthRevenue.previousMonthName}</span>
                                                    <span>{widgetretention.previousMonthRetentionPercent}%</span>
                                                </p>
                                                <p className='status-percentage mt-sm-22 mt-md-0'><span>{widgetretention.previousMonthActiveRecords} out of {widgetretention.previousMonthTotalRecords}</span></p>
                                            </Col>
                                        {/* </Row> */}
                                    </div>
                                </Col>}
                            </>
                        )}
                    </Row>
                    <Row className='grid-box-row p-0 mt-3'>
                        <Col xs={12} md={7} className=''>
                            <div className='grid-box h-100 m-0'>
                                <div className='grid-box-heading d-flex justify-content-between align-items-center'>
                                    <div className=''>
                                        <h4 style={{ color: "#f5aa33" }}>
                                            Store Listing
                                        </h4>
                                    </div>
                                    <div className="boxsize filter-dropdown boxsize-transparent p-2">
                                        <button className={showdropdownfilter.status === 'all' ? "active fs-6" : "fs-6"} onClick={setAll}>All</button>
                                        <button className={showdropdownfilter.status === 'Active' ? "active fs-6" : "fs-6"} onClick={setActive}>Active</button>
                                        <button className={showdropdownfilter.status === 'inActive' ? "active fs-6" : "fs-6"} onClick={setInActive}>InActive</button>
                                    </div>
                                </div>
                                <div className='table-responsive table-store-listing'>
                                    <Tablelist tabledata={users.tabledata} loading={users.loading} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={5} className=''>

                            <div className='grid-box m-0 h-50'>
                                <div className='grid-box-heading mb-0'>
                                    <div>
                                        <h4 style={{ color: "#f5aa33" }}>
                                            Plans Count
                                        </h4>
                                    </div>
                                    <div className='dropdown filter-dropdown'>
                                        <button style={{ color: "#f5aa33" }} className='dropdown-toggle bg-light p-1 px-2 rounded-2 fs-6' id='dropdownaction' aria-haspopup="tree" data-bs-toggle="dropdown" aria-expanded="false">
                                            {showdropdownfilter.frequency === "week" && <>Last 7 DAYS</>}
                                            {showdropdownfilter.frequency === "month" && <>Last 30 DAYS</>}
                                            {showdropdownfilter.frequency === "thisweek" && <>This Week</>}
                                            {showdropdownfilter.frequency === "thismonth" && <>This Month</>}
                                            {showdropdownfilter.frequency === "all" && <>All Time</>}
                                        </button>
                                        <div className="dropdown-menu boxsize boxsize-transparent p-2" aria-labelledby='dropdownaction'>
                                            <button className={showdropdownfilter.frequency === 'all' ? "active fs-6 dropdown-item text-start" : "fs-6 dropdown-item text-start"} onClick={() => fetchTimely("all")}>All Time</button>
                                            <hr className='dropdown-divider' />
                                            <button className={showdropdownfilter.frequency === 'week' ? "active fs-6 dropdown-item text-start" : "fs-6 dropdown-item text-start"} onClick={() => fetchTimely("week")}>Last 7 Days</button>
                                            <hr className='dropdown-divider' />
                                            <button className={showdropdownfilter.frequency === 'month' ? "active fs-6 dropdown-item text-start" : "fs-6 dropdown-item text-start"} onClick={() => fetchTimely("month")}>Last 30 DAYS</button>
                                            <hr className='dropdown-divider' />
                                            <button className={showdropdownfilter.frequency === 'thisweek' ? "active fs-6 dropdown-item text-start" : "fs-6 dropdown-item text-start"} onClick={() => fetchTimely("thisweek")}>This Week</button>
                                            <hr className='dropdown-divider' />
                                            <button className={showdropdownfilter.frequency === 'thismonth' ? "active fs-6 dropdown-item text-start" : "fs-6 dropdown-item text-start"} onClick={() => fetchTimely("thismonth")}>This Month</button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    charttabledata.loading ? <Loading /> : <>
                                        {charttabledata.tbody.length > 0 ? <PieCharts data={charttabledata.tbody} data992={1.5} irr={70} orr={90} DataKey={"value"} /> : <div className='text-center '>No Record Found</div>}
                                    </>
                                }
                            </div>
                            <div className="grid-box m-0 h-50 p-2 table-plan-count table-responsive">
                                {
                                    charttabledata.loading ? <Loading /> : <Tablelist tabledata={charttabledata} loading={users.loading} />
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row className='grid-box-row p-0 mt-3'>
                        <Col xs={12} md={6} className=''>
                            <div className='grid-box m-0'>
                                <div className='grid-box-heading'>
                                    <div className=''>
                                        <h4 >
                                            Country Wise Installs
                                        </h4>
                                    </div>
                                    <div className='dropdown filter-dropdown'>
                                        <button style={{ color: "#f5aa33" }} className='dropdown-toggle bg-light p-2 rounded-2 fs-6' id="dropdownexecute" aria-haspopup="true" aria-expanded="false" data-bs-toggle="dropdown">
                                            {countryWiseInstalls.frequency === "all" && <>All Time</>}
                                            {countryWiseInstalls.frequency === "week" && <>Last 7 DAYS</>}
                                            {countryWiseInstalls.frequency === "month" && <>Last 30 DAYS</>}
                                            {countryWiseInstalls.frequency === "thisweek" && <>This Week</>}
                                            {
                                                countryWiseInstalls.frequency === "thismonth" && <>This Month</>
                                            }
                                        </button>
                                        <div className='dropdown-menu boxsize boxsize-transparent p-2' aria-labelledby='dropdownexecute'>
                                            <button className={countryWiseInstalls.frequency === "all" ? 'dropdown-item fs-6 text-start active' : 'dropdown-item fs-6 text-start'} onClick={() => setCountryWiseInstalls({ ...countryWiseInstalls, frequency: "all" })}>
                                                All Time
                                            </button>
                                            <hr className='dropdown-divider' />
                                            <button className={countryWiseInstalls.frequency === "thisweek" ? "dropdown-item fs-6 active text-start" : "dropdown-item fs-6 text-start"} onClick={() => setCountryWiseInstalls({ ...countryWiseInstalls, frequency: "thisweek" })}>
                                                This Week
                                            </button>
                                            <hr className='dropdown-divider' />
                                            <button className={countryWiseInstalls.frequency === "thismonth" ? "dropdown-item fs-6 active text-start" : "dropdown-item fs-6 text-start"} onClick={() => setCountryWiseInstalls({ ...countryWiseInstalls, frequency: "thismonth" })}>
                                                This Month
                                            </button>
                                            <hr className='dropdown-divider' />
                                            <button className={countryWiseInstalls.frequency === "week" ? "dropdown-item fs-6 active text-start" : "dropdown-item fs-6 text-start"} onClick={() => setCountryWiseInstalls({ ...countryWiseInstalls, frequency: "week" })}>
                                                Last 7 Days
                                            </button>
                                            <hr className='dropdown-divider' />
                                            <button className={countryWiseInstalls.frequency === "month" ? "dropdown-item fs-6 active text-start" : "dropdown-item fs-6 text-start"} onClick={() => setCountryWiseInstalls({ ...countryWiseInstalls, frequency: "month" })}>
                                                Last 30 Days
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='table-country-installs table-responsive'>
                                    <Tablelist tabledata={countryWiseInstalls.tabledata} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='grid-box'>
                                <h4 style={{color:"#f5aa33"}}>Country </h4>
                            </div>
                            <div className='grid-box table-responsive'>
                                <div className='grid-box-heading'></div>
                                <RadarChart height={500} data={radarData} width={500}>
                                    <PolarGrid />
                                    <PolarAngleAxis />
                                    <PolarRadiusAxis />
                                    <Radar dataKey={"x"} fill='lightblue' fillOpacity={"green"} stroke='green' />
                                </RadarChart>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
export default Widgets;