import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tablelist from "../../../../Hook/list";
import { useSearchParams } from "react-router-dom";
import useSearchParamsState from "../../../../Hook/useSearchparamsstate";
import axios from "../../../../Hook/utils/constants/server";
import Loading from "../../../../Hook/Spinner";
import Pagination from "../../../../Hook/list/listfooter";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
import { FaEye } from "react-icons/fa";
import Timeago from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
Timeago.addDefaultLocale(en);
const timeago = new Timeago("en-US")
const tableHeadData = {
    users: [{
        type: "text",
        title: "Sr No.",
        key: "serial",
    },

    {
        type: "text",
        title: "Email/Session",
        key: "email"
    }, {
        type: "text",
        title: "User Type",
        key: 'user_type'
    },
    {
        type: "text",
        title: 'Wishlist Items',
        key: "wishlist_items_count"
    },
    {
        type: "text",
        title: "Cart Items",
        key: "cart_items_count"
    },
    {
        type: "text",
        title: "Created At",
        key: "created_at"
    },
    {
        type: "action",
        title: "Details",
        element: [{ type: "edit", icon: FaEye, action: () => console.log("Eye opened") }]
    }
    ],
    wishlistItems: [{
        type: "text",
        title: "Serial No.",
        key: "serial"
    },
    {
        type: "text",
        title: "Item Name",
        key: "title"
    }, {
        type: "text",
        title: "Email/Session",
        key: "email"
    },
    {
        type: "text",
        title: "User Type",
        key: "user_type"
    },
    {
        type: "text",
        title: "Account Created",
        key: "account_created"
    },
    {
        type: "action",
        title: "Details",
        element: [{
            type: "eidt", icon: FaEye, action: () => {
                console.log("Action triggered for the wishlist items")
            }
        }]
    }
    ]
}
const withRouter = Component => {
    const WrapRouterComponent = (props) => {
        const params = useSearchParams();
        const [searchParamsstate, setSearchParamsstate] = useSearchParamsState();
        React.useEffect(() => {
            if (!searchParamsstate.get("id") && !searchParamsstate.get("pageNumber"))
                setSearchParamsstate({ pageNumber: 1, id: 1 });
            else if (!searchParamsstate.get("id"))
                setSearchParamsstate({ id: 1 });
            else if (!searchParamsstate.get("pageNumber"))
                setSearchParamsstate({ pageNumber: 1 });

        }, []);
        return <Component {...props} activeTab={searchParamsstate.get("activetab") ? searchParamsstate.get("activetab") : "Users"} page={searchParamsstate.get("pageNumber") ? Number(searchParamsstate.get("pageNumber")) : 1} id={searchParamsstate.get("id") ? Number(searchParamsstate.get("id")) : 1} searchParamsstate={searchParamsstate} setSearchParamsstate={setSearchParamsstate} />
    }
    return WrapRouterComponent;
}
class StoresubDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: {
                users: {
                    name: "Users",
                    active: this.props.activeTab ? (this.props.activeTab === 'Wishlist Items' ? false : true) : true,
                },
                wishlistItems: {
                    name: "Wishlist Items",
                    active: this.props.activeTab === "Users" ? false : true,
                }
            },
            tabledata: {
                tablerange: [],
                thead: tableHeadData.users,
                tbody: []
            },
            spinner: true,
            defaultPage: this.props.searchParamsstate.get("pageNumber") ? this.props.searchParamsstate.get("pageNumber") : 1,
            recordsPerPage: this.props.searchParamsstate.get('frequencyCount') ? this.props.searchParamsstate.get("frequencyCount") : 25
        }
        this.fetchDetails = this.fetchDetails.bind(this);
        this.changePage = this.changePage.bind(this);
        this.changeRecordsPerpage = this.changeRecordsPerpage.bind(this);
        this.calculateRange = this.calculateRange.bind(this);
        this.modifyData = this.modifyData.bind(this);
        this.toggleTabs = this.toggleTabs.bind(this);
    }
    toggleTabs() {
        this.setState(state => ({ ...state, tabs: { ...this.state.tabs, users: { ...this.state.tabs.users, active: this.state.tabs.users["active"] ? false : true }, wishlistItems: { ...this.state.tabs.wishlistItems, active: this.state.tabs.wishlistItems.active ? false : true } } }));
        this.props.setSearchParamsstate({ activetab: this.state.tabs.users["active"] ? this.state.tabs.wishlistItems.name : this.state.tabs.users.name })
    }
    changePage(e, page) {

        if (this.state.defaultPage !== page) {
            this.setState(state => ({ ...state, defaultPage: page }));
            this.props.setSearchParamsstate({ pageNumber: page });
        }
        else console.log(this.state.defaultPage, page)
    }
    changeRecordsPerpage(e, count) {
        this.setState(state => ({ ...state, recordsPerPage: count }));
        this.props.setSearchParamsstate({ frequencyCount: count });
    }
    calculateRange(dataCount, recordsPerpage) {
        const paginationCount = Math.ceil(dataCount / recordsPerpage);
        let range = [];
        for (let i = 1; i <= paginationCount; i++) {
            range.push(i);
        }
        return range;
    }
    positive = [];
    modifyData(data, recordsPerPage, page) {
        let indexedResponse = this.positive.sort((a, b) => a - b).slice((recordsPerPage * page - recordsPerPage), (page * recordsPerPage));
        let index = data[data.length - 1];
        return data.map((data, positive) => ({ ...data, created_at: new Date(data.created_at).toLocaleString().split(",")[0], wishlist_items_count: data.wishlist_items_count, cart_items_count: data.cart_items_count, serial: ((this.state.defaultPage * this.state.recordsPerPage - (this.state.recordsPerPage) + 1) + positive) }))
    }
    render() {
        return (
            <>
                <Container className="main-container">
                    <Row className="grid-box-row">
                        <Col xs={12}>
                            <div className="grid-box">
                                <div className="grid-box-heading rev">
                                    <div className="boxsize box-size-transparent boxsize-transparent w-100">
                                        {
                                            Object.entries(this.state.tabs).map(([key, value], index) => <button key={index} onClick={(e) => this.toggleTabs()} className={this.state.tabs[key]["active"] ? "active-tab tab " : "inactive-tab tab "}>
                                                {
                                                    this.state.tabs[key]["name"]
                                                }
                                            </button>)
                                        }
                                    </div>
                                </div>
                                <div className="table-responsive sub-details">
                                    {this.state.spinner && <Loading />}
                                    {!this.state.spinner && <Tablelist tabledata={this.state.tabledata} />}
                                    {!this.state.spinner && this.state.defaultPage > 0 && <div className="p-5"> <Pagination page={this.props.page} range={this.state.tabledata.tablerange ? this.state.tabledata.tablerange : [1, 2, 3, 4, 5, 6, 7]} changePage={this.changePage} recordsPerPage={{
                                        defaultValue: this.state.recordsPerPage,
                                        list: [25, 50, 75, 100],
                                        menuOpen: false,
                                    }} changeRecordsPerpage={this.changeRecordsPerpage} /></div>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
    async fetchDetails(type, app_install_id) {
        let response = await axios("/user/storeSubdetails", {
            method: "GET",
            params: {
                app_install_id,
                recordsPerPage: this.state.recordsPerPage,
                type,
                page: this.props.page ? this.props.page : 1
            }
        });
        return {
            data: response.data.data,
            maxPage: response.data.maxPage,
            userCount: response.data.userCount,
            wishlist_users_product_count: response.data.wishlist_users_product_count
        };
    }
    componentDidMount() {
        this.setState({ spinner: true });
        this.fetchDetails(this.state.tabs.users.active ? this.state.tabs.users.name : this.state.tabs.wishlistItems.active ? this.state.tabs.wishlistItems.name : "", this.props.id).then(list => {
            this.positive = [];
            for (let i = 1; i <= list.userCount; i++) {
                this.positive.push(i);
            }
            this.setState({ tabledata: { ...this.state.tabledata, thead: this.props.activeTab === "Users" ? tableHeadData.users : tableHeadData.wishlistItems, tbody: this.modifyData(list.data, this.state.recordsPerPage, this.state.defaultPage), tablerange: this.calculateRange(list.userCount, this.state.recordsPerPage) }, defaultPage: list.maxPage, spinner: false });

            this.props.setSearchParamsstate({ pageNumber: list.maxPage, activetab: this.state.tabs.users ? this.state.tabs.users.name : this.state.tabs.wishlistItems.name });
        }).finally(response => response);

    }
    componentDidUpdate(previousProps, previousState) {
        if (previousState.defaultPage !== this.state.defaultPage || previousState.recordsPerPage !== this.state.recordsPerPage) {
            this.setState({ spinner: true });
            this.fetchDetails(this.state.tabs.users.active ? this.state.tabs.users.name : this.state.tabs.wishlistItems.active ? this.state.tabs.wishlistItems.name : null, this.props.id).then(list => {
                this.positive = [];
                for (let i = 1; i <= list.userCount; i++) {
                    this.positive.push(i);
                }
                this.setState({ spinner: false, tabledata: { ...this.state.tabledata, tbody: this.modifyData(list.data, this.state.recordsPerPage, this.state.defaultPage), tablerange: this.calculateRange(list.userCount, this.state.recordsPerPage) }, defaultPage: list.maxPage });
                this.props.setSearchParamsstate({ pageNumber: list.maxPage });
            }).catch(er => console.log(er)).finally(response => response);
        }
        if (previousState.tabs !== this.state.tabs) {
            this.setState({ spinner: true });
            this.fetchDetails(this.state.tabs.users.active ? this.state.tabs.users.name : this.state.tabs.wishlistItems.active ? this.state.tabs.wishlistItems.name : null, this.props.id).then(list => {
                this.positive = [];
                for (let i = 1; i <= list.userCount; i++) {
                    this.positive.push(i);
                }
                this.setState((state) => ({ ...state, tabledata: { ...state.tabledata, tablerange: this.calculateRange(list.userCount, this.state.recordsPerPage), thead: this.state.tabs.users.active ? tableHeadData.users : tableHeadData.wishlistItems, tbody: this.modifyData(list.data, this.state.recordsPerPage, this.state.defaultPage) }, defaultPage: list.maxPage, spinner: false })); this.props.setSearchParamsstate({ pageNumber: list.maxPage })
            });
        }
        else {
            return;
        }
    }
}
const StoresubDetailRouter = withRouter(StoresubDetail);
export default StoresubDetailRouter;