// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-sec {
  padding: 20px 30px 18px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  box-shadow: rgba(146, 153, 184, 0.05) 0px -5px 10px;
}
.footer-sec p {
  margin: 0;
  color: #9299B8;
}
.footer-sec ul {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  gap: 0 15px;
}
.footer-sec a {
  color: #9299B8;
}
.footer-sec a:hover {
  color: #f5aa33;
}

/** Media Query **/
@media screen and (max-width: 767px) {
  .footer-sec ul {
    justify-content: center;
  }
  .footer-sec {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/Footer/Footer.scss"],"names":[],"mappings":"AAKA;EACI,uBAAA;EACA,0BAAA;EACA,eAAA;EACA,oCAAA;EACA,WAAA;EACA,mDAAA;AAJJ;AAMI;EACI,SAAA;EACA,cAbS;AASjB;AAOI;EACI,SAAA;EACA,gBAAA;EACA,UAAA;EACA,aAAA;EACA,yBAAA;EACA,WAAA;AALR;AAQI;EACI,cA1BS;AAoBjB;AAQQ;EACI,cA5BG;AAsBf;;AAWA,kBAAA;AAEA;EACI;IACI,uBAAA;EATN;EAYE;IACI,kBAAA;EAVN;AACF","sourcesContent":["@use 'sass:math';\r\n\r\n$lighgrey-color: #9299B8;\r\n$orange-color: #f5aa33;\r\n\r\n.footer-sec {\r\n    padding: 20px 30px 18px;\r\n    color: rgba(0, 0, 0, 0.65);\r\n    font-size: 14px;\r\n    background: rgba(255, 255, 255, 0.9);\r\n    width: 100%;\r\n    box-shadow: rgb(146 153 184 / 5%) 0px -5px 10px;\r\n\r\n    p {\r\n        margin: 0;\r\n        color: $lighgrey-color;\r\n    }\r\n\r\n    ul {\r\n        margin: 0;\r\n        list-style: none;\r\n        padding: 0;\r\n        display: flex;\r\n        justify-content: flex-end;\r\n        gap: 0 15px;\r\n    }\r\n\r\n    a {\r\n        color: $lighgrey-color;\r\n\r\n        &:hover {\r\n            color: $orange-color;\r\n        }\r\n    }\r\n}\r\n\r\n/** Media Query **/\r\n\r\n@media screen and (max-width: 767px) {\r\n    .footer-sec ul {\r\n        justify-content: center;\r\n    }\r\n\r\n    .footer-sec {\r\n        text-align: center;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
