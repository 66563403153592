import React from "react";
import { useState, useEffect } from "react";
const useTable = (data, dataCount, page, recordsperpage) => {
    // let [tablerange, setTablerange] = useState([]);
    // let [slicedData, setSlicedData] = useState([]);
    const calculateRange = (dataCount, recordsperpage) => {
        let reange = [];
        let pages = Math.ceil(dataCount / recordsperpage);
        for (let i = 1; i <= pages; i++) {
            reange.push(i);
        }
        return reange;
    }
    // const calculateSlicedData = (data, page, recordsperpage) => {
    //     let slicedData = data.slice((page - 1) * recordsperpage, page * recordsperpage);
    //     return slicedData;
    // }
    // useEffect(() => {
    //     setTablerange(calculateRange(dataCount, recordsperpage));
    //     // setSlicedData(calculateSlicedData(data, page, recordsperpage))
    // }, [data, page, dataCount, recordsperpage]);
    return {
        slicedData: data,
        tablerange: calculateRange(dataCount, recordsperpage)
    }

}
export default useTable;