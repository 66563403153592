import React, { useEffect, useRef, useState, useLayoutEffect, useCallback } from "react";
import axios from "../../Hook/utils/constants/server";
import { useSearchParams, useLocation, useNavigate, createSearchParams, Routes, Route } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import TableList from "../../Hook/list";
import Countries from "../Data/countries/countries.json";
import useTable from "../../Hook/useTable";
import Pagination from "../../Hook/list/listfooter";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import Calendar from "react-calendar";
import "../../../src/Hook/CustomHook.css";
import useSearchParamsState from "../../Hook/useSearchparamsstate";
import "./index.scss";
import StoreDetailContent from "./storedetail";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
let pageValue;
let countries = [{ name: "All", flag: "" }].concat(Countries.map(country => ({ flag: country.code.toLowerCase(), name: country.name })));
const ReportsScreen = () => {
    const location = useLocation();
    const [searchParamsState, setSearchParamsState] = useSearchParamsState();
    const [page, setPage] = useState(searchParamsState.get("pageNumber") ? Number(searchParamsState.get("pageNumber")) : 1);
    const inputSearch = useRef(null);
    const searchCountry = useRef(null);
    const calenderDate = useRef(null);
    const navigation = useNavigate();
    const [filters, setFilters] = useState({
        loader: false,
        list: [],
        listCount: [],
        defaultPage: searchParamsState.get("pageNumber") ? searchParamsState.get("pageNumber") : 1,
        status: {
            default: searchParamsState.get("status") ? searchParamsState.get("status") : "all",
            list: ["all", "Active", "inActive"],
            triger: false
        },
        store_type: {
            default: searchParamsState.get("store_type") ? searchParamsState.get("store_type") : "all",
            list: ["all", "live", "testing"],
            triger: false
        },
        setup: {
            default: searchParamsState.get("setup_completed") ? searchParamsState.get("setup_completed") : "both",
            list: ["both", "true", "false"],
            triger: false
        },
        recordsPerpagedropdown: {
            defaultValue: searchParamsState.get("frequency_count") ? searchParamsState.get("frequency_count") : 25,
            list: [25, 50, 75, 100],
            menuOpen: false,
        },
        dates: {
            date: searchParamsState.get("start_date") && searchParamsState.get("end_date") ? [`${searchParamsState.get("start_date")}`, `${searchParamsState.get("end_date")}`] : ['', ''],
            showCalendar: false,
            triger: false
        },
        countries: {

            list: countries,
            selectedCountry: {
                flag: searchParamsState.get("country") ? `${countries.find(country => country.name === searchParamsState.get("country")).flag}` : "",
                name: searchParamsState.get("country") ? searchParamsState.get("country") : "All"
            },
            triger: false
        },
        planname: {
            default: { label: searchParamsState.get("plan") ? searchParamsState.get("plan") : "All", value: "all" },
            list: [{
                label: "All",
                value: "All"
            }, { label: "Basic", value: "basic" }, { label: "Premium", value: "premium" }, { label: "Free", value: "free" }, { label: "Pro", value: "pro" }],
            triger: false
        }, search: {
            value: searchParamsState.get("search") ? searchParamsState.get("search") : "",
            enabled: searchParamsState.get("search") ? true : false
        }
    });
    const [tableList, setTableList] = useState({
        thead: [
            {
                type: "text",
                title: "Sr.",
                key: "serial"
            },
            {
                type: "text-link",
                title: "Shop Name",
                link: "shop_name",

            },
            {
                type: "text",
                title: "Active Plan",
                key: "active_plan_name"
            },
            {
                type: "text",
                title: "Country",
                key: "country"
            },
            {
                type: "text",
                title: "Date",
                key: "date"
            },
            {
                type: "text",
                title: "SetUp Completed",
                key: "setup_completed"
            },
            {
                type: "text",
                title: "Shop Email",
                key: "shop_email"
            },
            {
                type: "text",
                title: "Status",
                key: "status"
            },
            {
                type: "action",
                title: "Action",
                element: [{
                    type: "edit", icon: FaEye, action: (e, id) => {
                        positive(e, id);
                    }
                }]
            }
        ],
        tbody: [],
        tbodycopy: []
    });
    const handleOutside = useCallback(e => {
        if (!calenderDate.current.contains(e.target) && filters.dates.showCalendar && calenderDate.current) {
            setFilters((filters) => ({ ...filters, dates: { ...filters.dates, showCalendar: false } }))
        }
    }, [filters.dates.showCalendar])
    const handleInputSearch = e => {

        if (e.target.value === "") {
            setFilters({ ...filters, search: { ...filters.search, value: "" } })
        }
        else if (e.target.value === "") {
            setFilters({ ...filters, search: { ...filters.search, value: "" } })
        }
        else if (e.target.value !== "") {
            setFilters({ ...filters, search: { ...filters.search, value: e.target.value, enabled: true } })
        }
    };
    const handleFilterSearch = async () => {

        let statePairs = {};
        if (!filters.countries.triger && !filters.dates.triger && !filters.planname.triger && !filters.setup.triger && !filters.status.triger && !filters.store_type.triger && !filters.search.value !== "" && filters.search.enabled === false && (filters.search.enabled && filters.search.value === "")) {
            return;
        }
        else if (filters.countries.triger || filters.dates.triger || filters.planname.triger || filters.setup.triger || filters.status.triger || filters.store_type.triger || filters.search.value !== "" || filters.search.enabled) {

            if (filters.countries.triger) {

                statePairs["country"] = filters.countries.selectedCountry.name
            }
            if (filters.dates.triger) {
                if (filters.dates.date[0] !== "")
                    statePairs["start_date"] = filters.dates.date[0];
                if (filters.dates.date[1] !== "")
                    statePairs["end_date"] = filters.dates.date[1];
                if (filters.dates.date[0] === "") {

                    statePairs["start_date"] = ""
                }
                if (filters.dates.date[1] === "") {

                    statePairs["end_date"] = ""
                }
            }
            if (filters.setup.triger) {
                statePairs["setup_completed"] = filters.setup.default
            }
            if (filters.planname.triger) {
                statePairs["plan"] = filters.planname.default.label
            }
            if (filters.status.triger) {
                statePairs["status"] = filters.status.default
            }
            if (filters.store_type.triger) {

                statePairs["store_type"] = filters.store_type.default
            }
            if (filters.search.value !== "") {
                statePairs["search"] = filters.search.value;
            }
            if (filters.search.value === "") {

                statePairs["search"] = ""
            }

            setSearchParamsState({ ...statePairs });
            setFilters({
                ...filters, loader
                    : true
            })
            let response = await axios.get("/user/reports", {
                method: 'GET',
                params: {

                    pageNumber: searchParamsState.get("pageNumber") ? searchParamsState.get("pageNumber") : 1,
                    recordsPerpage: filters.recordsPerpagedropdown.defaultValue,
                    status: filters.status.default,
                    setup_completed: filters.setup.default,
                    store_type: filters.store_type.default,
                    country_name: filters.countries.selectedCountry.name,
                    start_date: filters.dates.date[0],
                    end_date: filters.dates.date[1],
                    plan: filters.planname.default.label,
                    search: filters.search.value
                }
            });
            if (response.data.data) {

                setSearchParamsState({ ...statePairs, pageNumber: response.data.data.maxPage })
                setFilters({ ...filters, search: { ...filters.search, enabled: true }, loader: false, listCount: response.data.data.recordCount });
                setTableList({ ...tableList, tbody: modifyData(response.data.data.list, response.data.data.maxPage), tbodycopy: modifyData(response.data.data.list, response.data.data.maxPage) })
            }
        }
    }
    const handleCountrySearch = e => {
        searchCountry.current.value = e.currentTarget.value;
        if (e.key === "Backspace" && searchCountry.current.value === "") {
            setFilters({
                ...filters, countries: {
                    ...filters.countries, list: countries
                }
            })
        }
        else if (e.key === "Backspace" && searchCountry.current.value !== "") {
            setFilters({
                ...filters, countries: {
                    ...filters.countries, list: Countries.filter(country => {
                        if (country.name.toLowerCase().includes(searchCountry.current.value.toLowerCase()) || country.name === searchCountry.current.value)
                            return country

                    }).map(country => ({
                        flag: country.code.toLowerCase(),
                        name: country.name
                    }))
                }
            })
        }
        else {
            setFilters({
                ...filters, countries: {
                    ...filters.countries, list: Countries.filter((country) => {
                        if (country.name.toLowerCase().includes(searchCountry.current.value.toLowerCase()) || country.name === searchCountry.current.value) {
                            return country
                        }
                    }
                    ).map(country => ({
                        flag: country.code.toLowerCase(),
                        name: country.name
                    }))
                }
            });
        }
    }
    const modifyData = (data, maxPage) => {
        let indexPositive = data[data.length - 1];
        let response = data.map((data, index) => ({ ...data, date: `${new Date(data.date).toLocaleString().split(",")[0]}`, serial: (((maxPage * filters.recordsPerpagedropdown.defaultValue - filters.recordsPerpagedropdown.defaultValue) + 1) + index) }));
        return response;
    }
    const onPageChange = (e, pageNumber) => {
        window.scrollTo({ top: 0, behavior: "smooth" })
        setPage(pageNumber);
        pageValue = pageNumber;
        setSearchParamsState({ pageNumber: pageNumber });
    }
    const positive = (e, id) => {
        navigation({
            pathname: "/dashboard/reports/storedetails",
            search: createSearchParams({ id }).toString(),
        });
    }
    const handleDateChange = e => {
        let dateSets = e.map(date => `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`);
        setFilters((filters) => ({ ...filters, dates: { ...filters.dates, date: [...dateSets], showCalendar: !filters.dates.showCalendar, triger: true } }));
    }
    const changeRecordsPerpage = (e, count) => {
        setFilters({ ...filters, recordsPerpagedropdown: { ...filters.recordsPerpagedropdown, defaultValue: count } });
        setSearchParamsState({ frequency_count: count })
    }
    const { slicedData, tablerange } = useTable(filters.list, filters.listCount, page, filters.recordsPerpagedropdown.defaultValue);
    React.useEffect(() => {
        let list = [...searchParamsState.entries()];
        if (tablerange.length === 0) {
            if (searchParamsState.get("pageNumber")) {
                pageValue = Number(searchParamsState.get("pageNumber"));
            }
            else {
                pageValue = 1;
            }
        }
        else if (tablerange.length > 0) {

            if (searchParamsState.get("pageNumber")) {
                if (tablerange[tablerange.length - 1] < searchParamsState.get("pageNumber")) {
                    // console.log("check")
                    pageValue = Number(tablerange[tablerange.length - 1]);
                    setSearchParamsState({ pageNumber: pageValue });
                }
                else if (tablerange[tablerange.length - 1] > searchParamsState.get("pageNumber")) {
                    // console.log("second")
                    pageValue = Number(searchParamsState.get("pageNumber"));
                    setSearchParamsState({ pageNumber: pageValue })
                }
                else if (tablerange[tablerange.length - 1] == (searchParamsState.get("pageNumber"))) {
                    // console.log("Positive")
                    pageValue = Number(searchParamsState.get("pageNumber"));
                    setSearchParamsState({ pageNumber: pageValue })
                }
            }
            else {
                pageValue = 1;
                setSearchParamsState({ pageNumber: pageValue })
            }
        }
        if (list.length === 1 && list[0][0].includes("pageNumber") || list.length === 0) {
            setFilters((filters) => ({ ...filters, loader: true }));
            axios.get("/user/reports", {
                method: "GET",
                params: {
                    recordsPerpage: filters.recordsPerpagedropdown.defaultValue,
                    pageNumber: pageValue,
                    status: searchParamsState.get("status"),
                    store_type: searchParamsState.get("store_type"),
                    country_name: searchParamsState.get("country"),
                    setup_completed: searchParamsState.get("setup_completed"),
                    start_date: searchParamsState.get("start_date"),
                    end_date: searchParamsState.get("end_date"),
                    plan: searchParamsState.get("plan"),
                    search: searchParamsState.get("search")
                }
            }).then(response => {
                setSearchParamsState({ pageNumber: response.data.data.maxPage })
                setFilters({ ...filters, listCount: response.data.data.recordCount, loader: false });
                setTableList({ ...tableList, tbody: modifyData(response.data.data.list, response.data.data.maxPage), tbodycopy: modifyData(response.data.data.list, response.data.data.maxPage) });
            }).catch(er => {
                console.log("Error", er)
                throw new Error(er)
            })
        }
        else {
            axios.get("/user/reports", {
                method: 'GET',
                params: {
                    pageNumber: pageValue,
                    recordsPerpage: filters.recordsPerpagedropdown.defaultValue,
                    status: searchParamsState.get("status"),
                    setup_completed: searchParamsState.get("setup_completed") ? searchParamsState.get("setup_completed") : null,
                    store_type: searchParamsState.get("store_type") ? searchParamsState.get("store_type") : null,
                    country_name: searchParamsState.get("country") ? searchParamsState.get("country") : null,
                    start_date: searchParamsState.get("start_date") ? searchParamsState.get("start_date") : null,
                    end_date: searchParamsState.get("end_date") ? searchParamsState.get("end_date") : null,
                    plan: searchParamsState.get("plan") ? searchParamsState.get("plan") : null,
                    search: searchParamsState.get("search")
                }
            }).then(response => {
                setSearchParamsState({ pageNumber: response.data.data.maxPage })
                setFilters({ ...filters, listCount: response.data.data.recordCount });
                setTableList({ ...tableList, tbody: modifyData(response.data.data.list, response.data.data.maxPage), tbodycopy: modifyData(response.data.data.list, response.data.data.maxPage) });
            }).finally(positive => positive);
        }
    }, [pageValue, filters.recordsPerpagedropdown.defaultValue]);
    const cleardatesandClose = e => {
        setFilters({ ...filters, dates: { ...filters.dates, date: ['', ''], triger: true, showCalendar: false } });
    }
    return (
        <>
            <div className="container grid-box main-container">
                <div className="mb-0 border border-white p-2 d-md-flex align-items-md-center justify-content-md-between" style={{ position: "sticky" }}>
                    <div>
                        <h1 style={{ color: "#f5aa33" }}>
                            Reports
                        </h1>
                    </div>
                    <div className="search-country w-75">
                        <input className="search-input w-100" defaultValue={filters.search.value} onChange={handleInputSearch} placeholder="Search by shop name or shop url or store name or store owner" />
                    </div>
                </div>
                <div className="p-2 row">
                    <div className="filter-container ">
                        <div className="countries-filter filter filter-country dropdown d-flex flex-column">
                            <span >Sort By Country</span>
                            <OverlayTrigger onEntering={(e) => {
                                e.children[0].style.color = "#f5aa33";
                                e.children[1].style.backgroundColor = "#f5aa33"
                            }} overlay={<Tooltip style={{ zIndex: 10, color: "#f5aa33" }}>{filters.countries.selectedCountry.name}</Tooltip>} placement="bottom">
                                <button className="dropdown-toggle bg-light p-1 py-2 d-flex gap-4 rounded-2 d-flex align-items-center justify-content-between position-relative" id="dropdowncountry" aria-haspopup="true" aria-expanded="false" data-bs-toggle="dropdown" >
                                    <p className="mb-0 gap-2 d-flex align-items-center" >
                                        {filters.countries.selectedCountry.name !== "All" ?
                                            <span className="image-logo">
                                                <img className="" src={`https://flagcdn.com/${filters.countries.selectedCountry.flag}.svg`} />
                                            </span> : <span className="no-logo"></span>
                                        }
                                        <span className="selected-country text-start">
                                            {filters.countries.selectedCountry.name}
                                        </span>
                                    </p>
                                </button>
                            </OverlayTrigger>
                            <div className="dropdown-menu country-dropdown px-0 position-absolute" aria-labelledby="dropdowncountry" >
                                <div className="seacrh-input p-1 w-100 position-sticky top-0">
                                    <input className="w-100  search-input" onKeyUp={handleCountrySearch} ref={searchCountry} type="text" placeholder="Search by country" />
                                </div>
                                <div className="country-scroll">
                                    {filters.countries.list.length > 0 && filters.countries.list.map((country, index) => (
                                        <div className="dropdown-item p-1 d-flex align-items-center gap-2" key={index} onClick={() => {
                                            setFilters({
                                                ...filters, countries: {
                                                    ...filters.countries, selectedCountry: { ...filters.countries.selectedCountry, name: country.name, flag: country.flag },
                                                    list: [{ name: "All", flag: "" }, ...Countries.map(country => ({ name: country.name, flag: country.code.toLowerCase() }))], triger: true
                                                }
                                            });
                                            searchCountry.current.value = ""
                                        }

                                        } style={{ cursor: "pointer" }}>
                                            <span className="img-container">
                                                {country.flag !== "" &&
                                                    <img className="" style={{ width: "25px", height: "25px" }} src={`https://flagcdn.com/${country.flag}.svg`} />
                                                }
                                            </span>
                                            <span className="text-muted">
                                                {country.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="setup-filter filter filter-setup dropdown d-flex flex-column">
                            <span className="fs-6" style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }}>
                                SetUp
                            </span>
                            <button style={{ color: "#f5aa33" }} className="dropdown-toggle bg-light p-1 py-2 rounded-2 d-flex align-items-center gap-4 justify-content-between" id="dropdownsetup" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters.setup.default === "true" && "Completed"}
                                {filters.setup.default === "both" && "Both"}
                                {filters.setup.default === "false" && "Not completed"}
                            </button>
                            <div className="dropdown-menu p-2" aria-labelledby="dropdownsetup">
                                {filters.setup.list.map((setup_completed, index) => (
                                    <div className="dropdown-item" key={setup_completed} style={{ color: filters.setup.default === setup_completed ? "#f5aa33" : "", cursor: "pointer" }} onClick={() => {
                                        setFilters({ ...filters, setup: { ...filters.setup, triger: true, default: setup_completed } });
                                    }
                                    }>
                                        {setup_completed === "true" && "Completed"}
                                        {setup_completed === "both" && "Both"}
                                        {setup_completed === "false" && "Not Completed"}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="status-filter filter filter dropdown d-flex flex-column">
                            <span className="fs-6" style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }}>Status</span>
                            <button style={{ color: "#f5aa33" }} className="dropdown-toggle bg-light p-1 py-2 rounded-2 d-flex gap-4 align-items-center justify-content-between" id="dropdownstatus" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters.status.default === "all" && "All"}
                                {filters.status.default === "Active" && "Active"}
                                {filters.status.default === "inActive" && "In Active"}
                            </button>
                            <div className="dropdown-menu p-2" aria-labelledby="dropdownstatus">
                                {filters.status.list.map((status, index) => <div style={{ color: filters.status.default === status ? "#f5aa33" : "", cursor: "pointer" }} className="dropdown-item" key={index} onClick={() => {
                                    setFilters({ ...filters, status: { ...filters.status, default: status, triger: true } });
                                }}>
                                    {status === "all" && "All"}
                                    {status === "inActive" && "InActive"}
                                    {status === "Active" && "Active"}
                                </div>)}
                            </div>
                        </div>
                        <div className="store-type-filter filter filter dropdown d-flex flex-column">
                            <span className="fs-6" style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }}>Store</span>
                            <button className="dropdown-toggle bg-light p-1 py-2 rounded-2 d-flex gap-4 align-items-center justify-content-between" style={{ color: "#f5aa33" }} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownstoretype">
                                {filters.store_type.default === "live" && "Live"}
                                {filters.store_type.default === "all" && "All"}
                                {filters.store_type.default === "testing" && "Testing"}
                            </button>
                            <div className="dropdown-menu p-2" aria-labelledby="dropdownstoretype">
                                {filters.store_type.list.map((store_type) => <div onClick={() => {
                                    setFilters({ ...filters, store_type: { ...filters.store_type, triger: true, default: store_type } });
                                }
                                } key={store_type} style={{ color: filters.store_type.default === store_type ? "#f5aa33" : "", cursor: "pointer" }} className="dropdown-item">
                                    {store_type === "live" && "Live"}
                                    {store_type === "all" && "All"}
                                    {store_type === "testing" && "Testing"}
                                </div>)}
                            </div>
                        </div>
                        <div className="plan-name-filter filter filter dropdown d-flex flex-column">
                            <span className="mb-0 fs-6" style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }}>Plan Name</span>
                            <button className="dropdown-toggle bg-light d-flex justify-content-between align-items-center p-1 py-2 rounded-2 gap-4" style={{ color: "#f5aa33" }} id="planname" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters.planname.list.find(plan => plan.label === filters.planname.default.label).label}
                            </button>
                            <div className="dropdown-menu p-2" aria-labelledby="planname">
                                {filters.planname.list.map((plan, index) => <div className="dropdown-item" style={{ color: plan.label === filters.planname.default.label ? "#f5aa33" : "", cursor: "pointer" }} key={index} onClick={() => {
                                    setFilters({ ...filters, planname: { ...filters.planname, default: { ...filters.planname.default, label: plan.label }, triger: true } });
                                }
                                }>{plan.label}</div>)}
                            </div>
                        </div>
                        <div className={filters.dates.triger ? "date-wise filter position-relative d-flex flex-column align-items-start" : "date-wise filter position-relative"}>
                            <span style={{ color: "#f5aa33", fontSize: "14.5px", fontWeight: 600 }} className="fs-6">Dates</span>
                            <button ref={calenderDate} style={{ color: "#f5aa33" }} className="p-1 py-2 rounded-2 bg-light dropdown d-flex align-items-center justify-content-between w-100" onClick={() => setFilters({ ...filters, dates: { ...filters.dates, showCalendar: !filters.dates.showCalendar } })}>
                                {filters.dates.date.some(date => date.length > 0) > 0 ?
                                    <>
                                        <div className="position-relative d-flex gap-1 align-items-center" >
                                            <span className="label" >
                                                Start -
                                            </span>
                                            <span className="value">{filters.dates.date[0]}</span>
                                        </div>
                                        <div className="position-relative d-flex gap-1 align-items-center ">
                                            <span className="label" >End -</span>
                                            <span className="value">{filters.dates.date[1]}</span>
                                        </div>
                                    </> :
                                    <>
                                        <>
                                            Select Date Range
                                        </>
                                    </>}
                            </button>
                            {filters.dates.showCalendar && <div className="calender position-absolute">
                                <div className="calendar-container position-relative">
                                    <button className="clear-dates rounded-2 px-1" onClick={(e) => cleardatesandClose(e)}>
                                        Clear
                                    </button>
                                    <Calendar minDate={new Date('2024-08-01')} maxDate={new Date()} value={[filters.dates.date[0], filters.dates.date[1]]} ref={calenderDate} selectRange onChange={handleDateChange} />
                                </div>
                            </div>}
                        </div>
                        <div className="trigger-filters">
                            {/* <OverlayTrigger placement={"auto"} overlay={<Tooltip>{"Trigger Search"}</Tooltip>} onEntering={(e) => {
                                e.children[0].style.color = "#f5aa33";
                                e.children[1].style.backgroundColor = "#f5aa33";
                            }}> */}
                            <button onClick={() => handleFilterSearch()} className="button-filter text-light p-2 rounded-2" style={{ backgroundColor: "#f5aa33" }}>GO</button>
                            {/* </OverlayTrigger> */}
                        </div>
                    </div>
                </div>
                <div className="row p-2">
                    <div className="col-12 grid-box m-0 table-responsive reports-list p-2">
                        <TableList tabledata={tableList} loading={filters.loader} />
                    </div>
                </div>
                {!filters.loader &&
                    <div className="row p-2">
                        {
                            location.pathname.includes("reports") && tablerange.length > 0 &&
                            <div className="col-12 grid-box position-relative">
                                <Pagination recordsPerPage={filters.recordsPerpagedropdown} changeRecordsPerpage={changeRecordsPerpage} page={searchParamsState.get("pageNumber") ? Number(searchParamsState.get("pageNumber")) : 1} range={tablerange} slicedData={slicedData} changePage={onPageChange} />
                            </div>
                        }
                    </div>
                }
            </div>
        </>
    )
}
const Reports = () => {
    return (
        <Routes>
            <Route index element={<ReportsScreen />} />
            <Route path="/storedetails/*" element={<StoreDetailContent />} />
        </Routes>
    )
}
export default Reports;