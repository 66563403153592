import React, { useEffect } from "react";
import "../../Components/ChartPage/Chartpage.css";
import { Table } from "react-bootstrap";
import Countries from "../../Components/Data/countries/countries.json";
import ReactCountryFlag from "react-country-flag";
import Loading from "../Spinner.js";
import { Overlay, Tooltip, OverlayTrigger } from "react-bootstrap";
import { memo } from 'react';
import "./listfooter/index.scss";
const Tablelist = (props) => {
    const { loading } = props;
    return (
        <>
            {loading ? <Loading /> : <>
                <Table className="table-list">
                    <thead className="thead">
                        <tr key={1} className="tr">
                            {props.tabledata.thead.map((cell, index) => <th key={index} className="th">
                                {cell.title}
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.tabledata.tbody.length > 0 && !loading ? <>
                                {props.tabledata.tbody.map((row, rowindex) => <tr key={rowindex}>
                                    {props.tabledata.thead.map((cell, cellindex) => <td key={cellindex}>
                                        {cell.type === "text-link" && <a class="text-decoration-none" href={`https://${row[cell.link]}`} target="_blank" rel="noopener noreferrer">{row[cell.link]}</a>}
                                        {(cell.type === "thumbnail" && cell.type !== "text") &&
                                            <img className="" style={{ width: "45px", height: "45px" }} src={`https://flagcdn.com/${row[cell.image]}.svg`} />
                                        }
                                        {cell.type === "text" && (row[cell.key]) === null || row[cell.key] === "" ? "--" : row[cell.key]}
                                        {cell.type === "action" &&
                                            <span style={{ cursor: "pointer" }} key={cellindex}>
                                                {cell.element.map((type, index) =>
                                                    <type.icon key={index} color={"#f5aa33"} onClick={(e) => type.action(e, row["app_install_id"])} />
                                                )}
                                            </span>
                                        }
                                    </td>)}
                                </tr>)}
                            </> :
                                <>
                                    <tr className="">
                                        <td colSpan={props.tabledata.thead.length} className="text-center">
                                            NO RECORD FOUND!
                                        </td>
                                    </tr>
                                </>
                        }
                    </tbody>
                </Table>
            </>
            }
        </>

    )
}
export default memo(Tablelist); 