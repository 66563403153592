import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
const useSearchParamsState = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const setSearchParamsState = (params) => {
        let combinedeState = [...searchParams.entries()].reduce((accumulator, [key, value]) => {
            return { ...accumulator, [key]: value }
        }, {});
        let finalObject = { ...combinedeState, ...params };

        if (finalObject.pageNumber == 0) {
            let { pageNumber, ...rest } = finalObject;
            finalObject = rest;
        }
        if (finalObject.search === "") {
            let { search, ...rest } = finalObject;
            finalObject = rest;
        }
        if (finalObject.start_date === "") {
            let { start_date, ...rest } = finalObject;
            finalObject = rest;
        }
        if (finalObject.end_date === "") {
            let { end_date, ...rest } = finalObject;
            finalObject = rest;
        }
        // console.log(finalObject)
        setSearchParams(finalObject);
    }
    return [searchParams, setSearchParamsState]

}
export default useSearchParamsState;