// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.click {
  cursor: pointer;
}

.grab{
  cursor: grab;
}

.color {
  color: red;
}

.color1 {
  color: black;
}

.userListing-box h5, .modal-heading{
  color: #272b41;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  margin: 0;  
}
.todo-page .grid-box h5 {
  border-bottom: 1px solid #F1F2F6;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.todo-page table tbody tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  box-shadow: none;
  border: none;
}
.todo-page-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 15px;  
}
.modal-content .modal-footer {
  border: none;
  padding: 0 24px 20px; 
}`, "",{"version":3,"sources":["webpack://./src/Components/Todo/Todo.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;AACA;EACE,gCAAgC;EAChC,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,UAAU;EACV,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,QAAQ;EACR,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":[".click {\n  cursor: pointer;\n}\n\n.grab{\n  cursor: grab;\n}\n\n.color {\n  color: red;\n}\n\n.color1 {\n  color: black;\n}\n\n.userListing-box h5, .modal-heading{\n  color: #272b41;\n  font-weight: 500;\n  font-size: 15px;\n  line-height: 1.4;\n  margin: 0;  \n}\n.todo-page .grid-box h5 {\n  border-bottom: 1px solid #F1F2F6;\n  padding-bottom: 15px;\n  margin-bottom: 15px;\n}\n.todo-page table tbody tr {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0;\n  box-shadow: none;\n  border: none;\n}\n.todo-page-btn {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 8px;\n  margin-top: 15px;  \n}\n.modal-content .modal-footer {\n  border: none;\n  padding: 0 24px 20px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
