// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.main-container div.grid-box-heading button.tab {
  width: 50%;
  padding: 13px;
  border-radius: 4px;
  font-size: medium;
  transition: all 0.4s;
  cursor: pointer;
}
div.main-container div.grid-box-heading button.inactive-tab {
  color: gray;
  background-color: #fff;
}
div.main-container div.grid-box-heading button.active-tab {
  color: #fff;
  background-color: #f5aa33;
}
div.main-container .sub-details .table-list {
  min-width: 800px;
}
div.main-container .sub-details .table-list th:nth-child(2), div.main-container .sub-details .table-list td:nth-child(2), div.main-container .sub-details .table-list th:nth-child(3), div.main-container .sub-details .table-list td:nth-child(3) {
  width: 220px;
}
div.main-container .sub-details .table-list th:nth-child(5), div.main-container .sub-details .table-list td:nth-child(5) {
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/Components/reports/storedetail/storesubDetail/index.scss"],"names":[],"mappings":"AAOQ;EACI,UAAA;EAEA,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,oBAAA;EACA,eAAA;AAPZ;AAUQ;EACI,WAfK;EAgBL,sBAlBgB;AAU5B;AAWQ;EACI,WArBG;EAsBH,yBAxBc;AAe1B;AAaQ;EACI,gBAAA;AAXZ;AAYY;EACI,YAAA;AAVhB;AAYY;EACI,YAAA;AAVhB","sourcesContent":["$background-active-color: #f5aa33;\r\n$background-inactive-color: #fff;\r\n$active-color: #fff;\r\n$inactive-color: gray;\r\n\r\ndiv.main-container {\r\n    div.grid-box-heading {\r\n        button.tab {\r\n            width: 50%;\r\n\r\n            padding: 13px;\r\n            border-radius: 4px;\r\n            font-size: medium;\r\n            transition: all 0.4s;\r\n            cursor: pointer;\r\n        }\r\n\r\n        button.inactive-tab {\r\n            color: $inactive-color;\r\n            background-color: $background-inactive-color;\r\n        }\r\n\r\n        button.active-tab {\r\n            color: $active-color;\r\n            background-color: $background-active-color;\r\n        }\r\n    }\r\n    .sub-details {\r\n        .table-list {\r\n            min-width: 800px;\r\n            th:nth-child(2) , td:nth-child(2),th:nth-child(3),td:nth-child(3) {\r\n                width:220px;\r\n            }\r\n            th:nth-child(5),td:nth-child(5) {\r\n                width: 100px;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
