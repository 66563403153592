// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-theme {
  --header-color: white;
  --header-shadow: gray;
  --header-brand-name: black;
  --header-search-input: white;
  --header-search-input-placeholder: gray;
  --header-icon: black;
  --header-toggle-button: white;

  --sidebar-border: rgb(224, 223, 223);
  --sidebar-list-hover: rgb(217, 217, 217);
}

div#App {
  --height:100vh;
  height: var(--height);
}
.dark-theme {
  --header-color: rgb(32, 31, 31);
  --header-shadow: black;
  --header-brand-name: white;
  --header-search-input: rgb(181, 178, 178);
  --header-search-input-placeholder: rgb(89, 88, 88);
  --header-icon: white;
  --header-toggle-button: rgb(181, 178, 178);

  --sidebar-border: rgb(112, 111, 111);
  --sidebar-list-hover: rgb(175, 174, 174);
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,0BAA0B;EAC1B,4BAA4B;EAC5B,uCAAuC;EACvC,oBAAoB;EACpB,6BAA6B;;EAE7B,oCAAoC;EACpC,wCAAwC;AAC1C;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,+BAA+B;EAC/B,sBAAsB;EACtB,0BAA0B;EAC1B,yCAAyC;EACzC,kDAAkD;EAClD,oBAAoB;EACpB,0CAA0C;;EAE1C,oCAAoC;EACpC,wCAAwC;AAC1C","sourcesContent":[".default-theme {\n  --header-color: white;\n  --header-shadow: gray;\n  --header-brand-name: black;\n  --header-search-input: white;\n  --header-search-input-placeholder: gray;\n  --header-icon: black;\n  --header-toggle-button: white;\n\n  --sidebar-border: rgb(224, 223, 223);\n  --sidebar-list-hover: rgb(217, 217, 217);\n}\n\ndiv#App {\n  --height:100vh;\n  height: var(--height);\n}\n.dark-theme {\n  --header-color: rgb(32, 31, 31);\n  --header-shadow: black;\n  --header-brand-name: white;\n  --header-search-input: rgb(181, 178, 178);\n  --header-search-input-placeholder: rgb(89, 88, 88);\n  --header-icon: white;\n  --header-toggle-button: rgb(181, 178, 178);\n\n  --sidebar-border: rgb(112, 111, 111);\n  --sidebar-list-hover: rgb(175, 174, 174);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
