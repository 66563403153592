import React, { useEffect, useState } from "react";
import { Form, Alert, ToastContainer, ToastHeader, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import LoginJson from "../Data/Auth/auth.json";
import * as Error from "../../Constant/ErrorPage";
import { utils } from "../Store/Utils";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import visible from '../Image/eye.svg';
import visibleoff from '../Image/eye-slash.svg';
import LoginLogo from "../Image/login-logo.png";
import axios from "axios";
import { MD5 } from "crypto-js";
import { useSnackbar } from "notistack";
import "./Login.scss";
const Login = () => {
    const {
        register,
        formState: { errors, isSubmitted },
        handleSubmit, reset, setError, clearErrors
    } = useForm({
        criteriaMode: "all"
    });
    let username = "";
    let password = "";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [message, setMessage] = useState(searchParams.get('data'));
    const [hide, setHide] = useState(false);
    let [loader, setLoader] = useState(false);
    let [toast, setToast] = useState({
        open: false,
        message: ""
    });
    const check = true;
    const snackbar = useSnackbar();
    useEffect(() => {
        setTimeout(() => {
            setMessage('')
        }, 6000);
        navigate('/')
    }, [message, reset, navigate])
    //handle form submission//
    const onSubmit = (data) => {
        setLoader(true);
        axios.post("/user/login", {
            username: data.username,
            // password: MD5(data.password).toString(),
            password:data.password
        }).then(response => {
            setLoader(false);
            if (response.data.login) {
                setToast({ ...toast, message: "Loginned Successfully", open: true });
                localStorage.setItem("login",check)
                navigate("/dashboard/widgets");
                snackbar.enqueueSnackbar("Loginned Successfully", {
                    variant: "success",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                })
                reset({
                    username: "",
                    password: ""
                });
            }
            else if (!response.data.login) {
                snackbar.enqueueSnackbar("Invalid Credentials", {
                    variant: "error",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right"
                    },
                    autoHideDuration: 2000
                })
            }
        });
    };
    //toggle password show//
    const toggle = () => {
        setHide((prev) => !prev);
    };
    return (
        <section className="login-form">
            {loader ? <Spinner className="fs-1" color={"primary"} /> : <>
                <div className="siteLogo">
                    <a href="/"><img src={LoginLogo} alt="Logo" /></a>
                </div>
                <div className="loginForm-inner">
                    {message &&
                        <Alert variant="success">
                            {message}
                        </Alert>
                    }
                    <h3>Sign in to <span> Admin </span></h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3 position-relative">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter username"
                                {...register("username", {
                                    required: {
                                        value: true,
                                        message: "Username is required*"
                                    },
                                    onChange(event) {
                                        if (event.target.value.length > 16) {
                                            setError("username", {
                                                types: {
                                                    maxLength: "User Name shoudn't be grater than ten"
                                                }
                                            }, { shouldFocus: true })
                                        }
                                        else if (event.target.value === "") {
                                            setError("username", {
                                                types: {
                                                    required: "Username is required*"
                                                }
                                            })
                                        }
                                        else {
                                            clearErrors("username");
                                        }
                                    },
                                    maxLength: 16
                                })}
                                maxLength={17}
                            />
                            {errors.username && errors.username.types.maxLength && <span className="position-absolute" color="red" style={{ fontSize: "12.6px", color: "red", top: "66px", left: "10px" }}>{errors.username.types.maxLength}
                            </span>}
                            {errors.username && errors.username.types.required && <span className="position-absolute" color="red" style={{ fontSize: "12.6px", color: "red", top: "66px", left: "10px" }}>{errors.username.types.required}
                            </span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type={!hide ? "password" : "text"}
                                placeholder="Password"
                                {...register("password", {
                                    required: {
                                        value: true,
                                        message: "Password is required*"
                                    },
                                    onChange(event) {
                                        if (event.target.value.length === 0) {
                                            setError("password", {
                                                types: {
                                                    required: "Password is required*"
                                                }
                                            })
                                        }
                                        else {
                                            clearErrors("password");
                                        }
                                    }
                                })}
                            />
                            <i className='passwordvisible' style={{ cursor: 'pointer' }} onClick={toggle}>
                                {hide ? <img src={visible} alt="visible" /> : <img src={visibleoff} alt="visible off" />}
                            </i>
                            {errors.password && errors.password.types.required && <span className="position-absolute" color="red" style={{ fontSize: "12.6px", color: "red", top: "66px", left: "10px" }}>{errors.password.types.required}
                            </span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Check
                                {...register("checkbox", {
                                    required: {
                                        value: true,
                                        message: "Check is required*"
                                    },
                                    onChange(event) {
                                        if (!event.target.checkednetstnetsta) setError("checkbox", {
                                            types: {
                                                required: "Please check this*"
                                            }
                                        })
                                        else clearErrors("checkbox");
                                    }
                                })}
                                type="checkbox"
                                label="Check me out"
                                id="checkbox"
                            />
                            {errors.checkbox && errors.checkbox.types.required && <span className="position-absolute" color="red" style={{ fontSize: "12.6px", color: "red", top: "-4px", right: "180px" }}>{errors.checkbox.types.required}
                            </span>}
                            <Form.Text className="forget-password-text">
                                <NavLink to="/forgetpassword">Forgot password?</NavLink>
                            </Form.Text>
                        </Form.Group>
                        <button type="submit">LOGIN</button>
                    </form>
                </div>
            </>}
        </section>
    );
};
export default Login;
