import React, { useEffect, useState } from "react";
import { createSearchParams, Route, Routes, useLocation, useOutletContext } from "react-router-dom";
import axios from '../../../Hook/utils/constants/server';
import useSearchParamsState from "../../../Hook/useSearchparamsstate";
import Backdrop from "@mui/material/Backdrop";
import Loading from "../../../Hook/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./index.scss";
import Tablelist from "../../../Hook/list";
import Card from "react-bootstrap/Card";
import { ArrowLeft, ArrowUp } from "react-bootstrap-icons";
import data from "../../Data/Chart/Bar.json";
import { Bar, Tooltip, BarChart } from "recharts";
import { Calendar } from "react-calendar";
import Countries from "../../Data/countries/countries.json";
import { useNavigate } from "react-router-dom";
import StoresubDetailRouter from "./storesubDetail";
import { useSnackbar } from "notistack";
import 'react-calendar/dist/Calendar.css';
const modifydate = date => {
    let dateWithouttime = new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    });

    let dateWithtime = new Date(date).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    });
    return `${dateWithouttime} ${dateWithtime}`
}
const StoreDetailContent = () => {
    const [searchParamsState, setSearchParamsState] = useSearchParamsState();
    const navigate = useNavigate();
    const location = useLocation();
    const snackbar = useSnackbar();
    const [stateGroup, setStategroup] = useState({
        loader: false,
        storeDetail: {},
        store_logs: [],
        dates: {
            showCalender: false,
            dates: searchParamsState.get("start_date") || searchParamsState.get("end_date") ? [searchParamsState.get("start_date"), searchParamsState.get("end_date")] : ['', '']
        }
    });
    const positive = useOutletContext();
    const [tableData, setTabledata] = useState({
        thead: [{
            type: "text",
            title: "App Install Id",
            key: "app_install_id"
        }, {
            type: "text",
            title: "Log Id",
            key: "log_id"
        }, {
            type: "text",
            title: "Plan Name",
            key: "plan_name"
        }, {
            type: "text",
            title: "Date",
            key: "log_date"
        }],
        tbody: []
    });
    const [analyticsWidget, setAnalyticswidget] = useState([
        {
            widgettype: "Wishlist Users",
            tabname: "Users",
            subheading: "Total number of wishlist users",
            dataSet: {
                count: 0
            }
        },
        {
            widgettype: "Wishlist Items",
            tabname: "Wishlist Items",
            subheading: "Total number of wishlist items",
            dataSet: {
                count: 0
            }
        },
        {
            widgettype: 'Total Revenue',
            tabname: null,
            subheading: "Items added to the cart from the app is the total reveue",
            dataSet: {
                count: 0
            }
        },
        {
            widgettype: "Monthly Quota Used",
            tabname: null,
            subheading: "monthly quota is used",
            dataSet: {
                wishlistItems: 0,
                totalQuota: 0,
                percentUsed: 0
            }
        }
    ]);
    const fetchStoreDetail = async (params) => {
        const { id, start_, end_ } = params;
        const response = await axios.get("/user/storedetails", {
            method: "GET",
            params: {
                app_install_id: id,
                start_: start_,
                end_: end_
            }
        });

        return response;
    }
    const modifyData = data => {
        let modifiedData = data.map((key, index) => ({
            app_install_id: key.app_install_id,
            plan_name: key.plan_name,
            log_date: `${new Date(key.log_date).toLocaleString()}`,
            log_id: key.log_id
        }));
        return modifiedData;
    }
    const babaji = (e) => {
        stateGroup.dates.dates.some(date => date.length > 0) &&
            setSearchParamsState({ start_date: stateGroup.dates.dates.map(date => `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`)[0], end_date: stateGroup.dates.dates.map(date => `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`)[1] });
        stateGroup.dates.dates.every(date => date.length === 0) && setSearchParamsState({ start_date: "", end_date: "" })
    }
    const handleDateChange = date => {
        setStategroup({
            ...stateGroup, dates: {
                ...stateGroup.dates, dates:
                    date.map(date => `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`), showCalender: !stateGroup.dates.showCalender
            }
        });

    }
    useEffect(() => {
        !searchParamsState.get("id") && setSearchParamsState({ id: 1 })
        setStategroup({ ...stateGroup, loader: true });
        fetchStoreDetail({ id: searchParamsState.get("id") ? searchParamsState.get("id") : 1, start_: searchParamsState.get("start_date") ? searchParamsState.get("start_date") : "", end_: searchParamsState.get("end_date") ? searchParamsState.get("end_date") : "" }).then(response => {
            response && setStategroup({ ...stateGroup, loader: false, storeDetail: response.data.data[0], store_logs: response.data.store_logs });
            setTabledata({ ...tableData, tbody: modifyData(response.data.store_logs) });
            setAnalyticswidget([{ widgettype: "Wishlist Users", subheading: "Total number of wishlist users", tabname: "Users", dataSet: { count: response.data.wishlist_users } }, { widgettype: "Wishlist Items", subheading: "Total number of wishlist items", tabname: "Wishlist Items", dataSet: { count: response.data.wishlist_items } }, { widgettype: "Total Income", subheading: "Items added to the cart from the app is the total revenue", dataSet: { count: response.data.totalRevenue } }, { widgettype: "Monthly Quota", subheading: "Monthly Quota is used", dataSet: { wishlistItems: response.data.wishlistItems, totalQuota: response.data.monthlyQuota, percentUsed: response.data.percentUsed } }]);
        }).catch(er => {
            alert(`Something went wrong , please check the respective consoles`);
            if (er) throw new Error(er);
        });
    }, [searchParamsState.get("start_date"), searchParamsState.get("end_date")]);
    return (
        <>
            {stateGroup.loader ? <Loading /> :
                <Container fluid className="grid-box store-basic-details">
                    <div className="header-label row p-2 grid-box-heading">
                        <h3 className="page-label">Store Detail</h3>
                    </div>
                    <Row className="grid-box-heading detail-section-container p-2">
                        <Col xs={12} className="bg-light">
                            <div className="p-2">
                                <h3 className="page-label">Basic Details</h3>
                            </div>
                            <div className="d-flex gap-2 justify-content-between w-100 p-2">
                                <span className="side-labels">Store Name - {stateGroup.storeDetail.store_name}</span>
                                <span className="side-labels">Active Plan - {stateGroup.storeDetail.active_plan_name}</span>
                            </div>
                            <div className="grid-list-container">
                                <GridList recordList={[...Object.entries(stateGroup.storeDetail)]} positive={stateGroup.loader} />
                            </div>
                        </Col>
                        <Col xs={12} className="p-2 bg-light">
                            <div className="p-2">
                                <h3>Installation Logs</h3>
                                <div className="log-list table-responsive p-3 bg-white">
                                    <Tablelist tabledata={tableData} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} className="p-2 bg-light analytics-page container grid-box">
                            <Row className="UserListing-row p-0">
                                <div className="d-flex stats-label align-items-center justify-content-between col-12">
                                    <h3 className="page-label w-50">
                                        Store Stats
                                    </h3>
                                    <div className="d-flex align-items-center justify-content-end gap-2">
                                        <div className="date-picker-container position-relative d-flex ">
                                            {
                                                stateGroup.dates.dates.some(date => date.length > 0) ?
                                                    <div className="d-flex dropdown-toggle justify-content-between align-items-center p-2 rounded-2 bg-white date-picker" onClick={() => setStategroup({ ...stateGroup, dates: { ...stateGroup.dates, showCalender: !stateGroup.dates.showCalender } })}>
                                                        <div className="d-flex  align-items-center gap-2">
                                                            <div className="start-date d-flex align-items-center ">
                                                                <span className="label">Start - </span>
                                                                <span className="value">{stateGroup.dates.dates[0]}</span>
                                                            </div>
                                                            <div className="end-date d-flex align-items-center">
                                                                <span className="label">End - </span>
                                                                <span className="value">{stateGroup.dates.dates[1]}</span>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div className="dropdown rounded-2 bg-white p-2 date-picker">
                                                        <div className="p-0 dropdown-toggle" onClick={(e) => setStategroup({ ...stateGroup, dates: { ...stateGroup.dates, showCalender: !stateGroup.dates.showCalender } })}>
                                                            Select Dates
                                                        </div>
                                                    </div>
                                            }
                                            {
                                                stateGroup.dates.showCalender && <div className="calendar-picker position-absolute text-muted">
                                                    <button className="clear-dates rounded-2 px-1" onClick={() => setStategroup({ ...stateGroup, dates: { ...stateGroup.dates, dates: ['', ''], showCalender: false } })}>Clear</button><Calendar className={"date-calender"} value={[stateGroup.dates.dates[0], stateGroup.dates.dates[1]]} minDate={new Date("2024-08-01")} maxDate={new Date()} selectRange onChange={handleDateChange} />
                                                </div>
                                            }
                                        </div>
                                        <div className="trigger-filters">
                                            <button onClick={babaji} className="button-filter rounded-2 p-1 px-2 text-light">Go</button>
                                        </div>
                                    </div>
                                </div>
                                {analyticsWidget.length > 0 && analyticsWidget.map((widget, index) =>
                                    <Col md={3} className="userListing-box bg-light px-1" key={index}>
                                        {
                                            widget.widgettype === "Monthly Quota" && <div className=" bg-white d-flex flex-column align-items-center w-100 p-2 rounded-2 justify-content-evenly">
                                                <h3>{widget.widgettype}</h3>
                                                <h2>{widget.dataSet.percentUsed}%</h2>
                                                <span className="fs-6">{widget.subheading}</span>
                                                <div className="view-container">
                                                    <button className="view-button" type={"button"}>
                                                        View
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        {
                                            widget.widgettype !== "Monthly Quota" &&
                                            <div className="bg-white d-flex flex-column align-items-center w-100 p-2 rounded-2 justify-content-evenly">
                                                <h3>{widget.widgettype}</h3>
                                                <h2>{Math.ceil(widget.dataSet.count)}</h2>
                                                <div className="view-container">
                                                    <button className="view-button" onClick={e => {

                                                        navigate(
                                                            {
                                                                pathname: `/dashboard/reports/storedetails/subDetails`,
                                                                search: createSearchParams({ id: searchParamsState.get("id") ? searchParamsState.get("id") : 1, activetab: widget.tabname }).toString()
                                                            }
                                                        )
                                                    }}>
                                                        View
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}

const GridList = (props) => {
    let { recordList, positive } = props;
    let firstSection = recordList.slice(0, Math.ceil(recordList.length / 2));
    let secondSection = recordList.slice(Math.ceil(recordList.length / 2));
    return (
        <>
            <Container fluid className="grid-box">
                <Row className="">
                    <Col xs={12} md={6} className="border border-0 border-end-1 border-muted p-1">
                        {firstSection.map(([key, value], index) => <div className="d-flex align-items-center gap-1 property-row" key={index}>
                            <label className="">{key}</label> -
                            <span>{value ? key.includes("date") ? modifydate(value) : value : "null"}</span>
                        </div>)}
                    </Col>
                    <Col xs={12} md={6} className="border border-0 border-start-1 border-muted p-1">
                        {secondSection.map(([key, value], index) => <div className='d-flex align-items-center gap-1 property-row' key={index}>
                            <label>{key}</label> -
                            <span>{value ? key === "log_date" ? modifydate(value) : value : "null"}</span>
                        </div>)}
                    </Col>
                </Row>
            </Container>
        </>
    )
}
const StoreDetail = () => {
    return (
        <Routes>
            <Route index path={"/"} element={<StoreDetailContent />} />
            <Route path="/subDetails/" element={<StoresubDetailRouter />} />
        </Routes>
    )
}
export default StoreDetail;