import React, { useEffect, useState } from "react";
import { ArrowRight, ArrowLeft } from "react-bootstrap-icons";
import "./index.scss";
const RenderContent = ({ activePage, range, changePage, recordsPerPage, changeRecordsPerpage }) => {
    let startElement = range[0];
    let lastelement = range[range.length - 1];
    let [visibleSlots, setVisibleSlots] = useState([]);
    let slotOperations = [-2, -1, 0, 1, 2];
    useEffect(() => {
        if (activePage - 2 > startElement && activePage + 2 < lastelement) {
            setVisibleSlots(slotOperations.map(slotOperation => {
                return activePage + slotOperation
            }))
        }
        else if (activePage - 2 === startElement) {
            setVisibleSlots(slotOperations.map(slotOperation => {
                return slotOperation + activePage
            }))
        }
        else if (activePage - 1 === startElement) {
            let slotsCopy = slotOperations;
            let resultPositive = slotsCopy.splice(slotsCopy.indexOf(-1));
            slotsCopy.forEach(slotCopy => {
                resultPositive.push(resultPositive[resultPositive.length - 1] + 1);
            });
            setVisibleSlots(resultPositive.map(slot => slot + activePage))
        }
        else if (activePage === startElement) {
            let slotsCopy = slotOperations;
            let splicedCopy = slotsCopy.splice(slotsCopy.indexOf(0));
            for (let i = 1; i <= slotsCopy.length; i++) {
                splicedCopy.push(splicedCopy[splicedCopy.length - 1] + 1)
            }
            setVisibleSlots(splicedCopy.map(slot => slot + activePage));
        }
        else if (activePage + 2 === lastelement) {
            setVisibleSlots(slotOperations.map(slot => {
                return slot + activePage
            }))
        }
        else if (activePage + 1 === lastelement) {
            let slotsCopy = slotOperations;
            let reversedCopy = slotsCopy.reverse();
            let splicedVersion = reversedCopy.splice(1);
            reversedCopy.forEach(slot => splicedVersion.push(splicedVersion[splicedVersion.length - 1] - 1));
            setVisibleSlots(splicedVersion.map((splicedValueSet, index) => splicedValueSet + activePage).reverse())
        }
        else if (activePage === lastelement) {
            let slotsCopy = slotOperations;
            let resultPositive = slotsCopy.splice(slotsCopy.indexOf(0));
            slotsCopy.forEach(slotCopy => {
                resultPositive.push(resultPositive[resultPositive.length - 1] + 1)
            })
            setVisibleSlots(resultPositive.map(slot => activePage - slot).reverse())
        }
    }, [activePage, range]);

    return (
        <div className="pagination">
            <div className="pagination-action">
                <div className="btn-group d-flex align-items-center gap-2">
                    <button className={activePage === range[0] ? "page-disabled text-light page" : "page-active page text-light"} disabled={activePage === startElement || !range ? true : false} onClick={(e) => changePage(e, startElement)}>First</button>
                    <button onClick={(e) => changePage(e, activePage - 1)} disabled={activePage === startElement ? true : false} className="page-pointer bg-light rounded-circle">
                        <ArrowLeft className="fw-bold" size={16} color={activePage === range[0] ? "#fdd28e" : "#f5aa33"} />
                    </button>
                </div>
                {range.length > 5 ? <>
                    {
                        (activePage - 2) > startElement &&
                        <div className="dot-slots">
                            <span className="pagination-dots">.</span><span className="pagination-dots">.</span><span className="pagination-dots">.</span>
                        </div>
                    }
                    <div className="number-slots d-flex align-items-center gap-2">
                        {visibleSlots.length > 0 && visibleSlots.map((visibleSlot, index) => <span className={visibleSlot === activePage ? "pagination-links active-page" : "pagination-links in-active-page"} onClick={(e) => changePage(e, visibleSlot)} key={index}>{visibleSlot}</span>)}
                    </div>
                    {
                        (activePage + 2) < lastelement &&
                        <div className="dot-slots">
                            <span className="pagination-dots">.</span><span className="pagination-dots">.</span><span className="pagination-dots">.</span>
                        </div>
                    }
                </> : <div className="number-slots d-flex align-items-center gap-2">
                    {range.length > 0 && range.map((visibleSlot, index) => <span className={visibleSlot === activePage ? "pagination-links active-page" : "pagination-links in-active-page"} onClick={(e) => changePage(e, visibleSlot)} key={index}>{visibleSlot}</span>)}
                </div>}
                <div className="btn-group d-flex align-items-center gap-2">
                    <button disabled={activePage === range.length || !range ? true : false} className="page-pointer rounded-circle bg-light" onClick={(e) => changePage(e, activePage + 1)}><ArrowRight color={activePage === range.length ? "#fdd28e" : "#f5aa33"} size={16} className="fw-bold" /></button>
                    <button className={activePage === lastelement ? "page-disabled text-light page" : "page page-active text-light"} disabled={activePage === lastelement ? true : false} onClick={(e) => changePage(e, lastelement)}>Last</button>
                </div>
            </div>
            <div className="frequency-customizer">
                <div className="page-list dropdown">
                    <button className="dropdown-toggle bg-light change-dropdown-page rounded-2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownpages">{activePage}</button>
                    <div className="dropdown-menu page-list-dropdown" aria-labelledby="dropdownpages">
                        {range.map((range, index) => <div onClick={(e) => changePage(e, range)} key={`key = ${index + range}`} style={{ color: activePage === range ? "#f5aa33" : "" }} className="dropdown-item">
                            {range}
                        </div>)}
                    </div>
                </div>
                <div className="dropdown page-range">
                    <button className="p-2 records rounded-2 dropdown-toggle bg-light" data-bs-toggle="dropdown" id="dropdownrecordcount" aria-haspopup="true" aria-expanded="false">
                        <span className="text-muted">Records/Page</span> -
                        <span> {recordsPerPage.defaultValue}</span>
                    </button>
                    <div className="dropdown-menu records-menu p-2" aria-labelledby="dropdownrecordcount" >
                        {recordsPerPage.list.map((count, index) => <div className="dropdown-item" onClick={(e) => changeRecordsPerpage(e, count)} key={index} style={{ color: recordsPerPage.defaultValue === count ? "#f5aa33" : "" }}>
                            {count}
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    )
}
const Pagination = (props) => {
    const { page, range, slicedData, changePage, recordsPerPage, changeRecordsPerpage } = props;
    return (
        <div className="d-flex gap-2">
            <RenderContent activePage={page} range={range} changePage={changePage} recordsPerPage={recordsPerPage} changeRecordsPerpage={changeRecordsPerpage} />
        </div>
    )
}
export default Pagination;
